import React from "react";
import dylformal from "../assets/dyl-formal.jpeg";
import dylflex from "../assets/dyl-flex.jpeg";

const About = () => {
  return (
    <div id="about" className="text-center bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-xl bold text-white min-h-screen">
      <h1 className="text-4xl font-serif text-white">Why Team DYL?</h1>
      <div className="flex flex-col md:flex-row justify-center items-center min-h-screen">
        {/* Left Column */}
        <div className="w-full md:w-1/2 relative overflow-hidden border border-gray-300">
          <div className="group relative">
            <img
              src={dylformal}
              alt="Image 1"
              className="w-full h-full object-cover transform scale-100 hover:scale-105 transition-transform duration-300 hover:shadow-md"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-2xl opacity-0 transition-opacity duration-300 group-hover:opacity-100 p-4">
              <p className="leading-loose">
                Individualized Programming <br />
                Meet Day Handling <br />
                Form Critique <br />
              </p>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="w-full md:w-1/2 relative overflow-hidden border border-gray-300">
          <div className="group relative">
            <img
              src={dylflex}
              alt="Image 2"
              className="w-full h-full object-cover transform scale-100 hover:scale-105 transition-transform duration-300 hover:shadow-md"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-2xl opacity-0 transition-opacity duration-300 group-hover:opacity-100 p-4">
              <p className="leading-relaxed md:leading-loose md:mr-4 md:ml-4 text-sm">
                Since 2021 I have competed in the USAPL at the local and national level.
                Powerlifting is something that opened so many doors for me, not
                only as a lifter, but as a person as well. When I see my athletes
                go through the same process that I did, the highs and the lows, it
                reminds me of how much I love this sport and the process that
                comes with it. Let's grow together!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
