import React from "react";
import dylCNATS from "../assets/dyl-cnats.jpeg";
import { TypeAnimation } from "react-type-animation";
const Main = () => {
  return (
    <div id="home">
      <div
        className="hero min-h-screen"
        style={{ backgroundImage: `url(${dylCNATS})` }}
      >
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-md">
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially

                1000, // wait 1s before replacing "Mice" with "Hamsters"
                "Team DYL",
                1000,
                "🦇The Future🦇",
                1000,
                "EST. 2022",
                500,
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: "2em", display: "inline-block" }}
              repeat={Infinity}
              className="mb-5 text-6xl font-bold"
            />

            <p className="mb-5 bold">
              Team DYL was created with the sole purpose of <br />
              <span className="text-purple-300 underline">forging high level athletes</span>
              <br />
              

            </p>
            <a href="#contact"><button className="btn btn-primary">Apply for Coaching</button></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
