import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="footer footer-center p-4 bg-base-300 text-base-content opacity-100">
  <div>
    <p>Copyright © 2023 - All rights reserved by Team DYL LLC</p>
  </div>
</footer>
    </div>
  )
}

export default Footer
