import React from 'react';

const Contact = () => {
  return (
    <div id="contact" className="flex flex-col items-center justify-center min-h-screen bg-blue-900 text-white">
      <div className="max-w-screen-md p-8 rounded-lg shadow-lg bg-gray-800">
        <h1 className="text-4xl font-bold text-center pb-6 text-blue-400">
          Apply to Join Team DYL
        </h1>
        <form
          action="https://getform.io/f/d35457ce-10ff-4b7b-be80-14441797c533" 
          method="POST"
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          <div className="flex flex-col">
            <label className="uppercase text-sm pb-2 text-white">Name</label>
            <input
              required
              className="border-2 rounded-lg p-4 text-lg bg-gray-300 text-gray-900"
              type="text"
              name="name"
            />
          </div>
          <div className="flex flex-col">
            <label className="uppercase text-sm pb-2 text-white">Current Squat/Bench/Deadlift</label>
            <input
              className="border-2 rounded-lg p-4 text-lg bg-gray-300 text-gray-900"
              type="text"
              name="phone"
            />
          </div>
          <div className="flex flex-col">
            <label className="uppercase text-sm pb-2 text-white">Email</label>
            <input
              className="border-2 rounded-lg p-4 text-lg bg-gray-300 text-gray-900"
              required
              type="email"
              name="email"
            />
          </div>
          <div className="flex flex-col">
            <label className="uppercase text-sm pb-2 text-white">About Yourself</label>
            <textarea
              className="border-2 rounded-lg p-4 text-lg bg-gray-300 text-gray-900"
              required
              rows={6}
              name="message"
            ></textarea>
          </div>
          <button className="bg-[#6c84c0] text-white mt-6 p-4 text-xl rounded-lg hover:bg-sky-700 transform hover:scale-105 transition-transform duration-300">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
