import React from "react";
import dylCNATS from "../assets/dyl-cnats.jpeg";
import dylFORMAL from "../assets/dyl-formal.jpeg";
import dylLOGO from "../assets/dyl-teamlogo.png";
const Navbar = () => {
  return (
    <div className="" >
<div className="navbar bg-black-300 fixed">
  <div className="flex-1">
    <a className="btn btn-ghost normal-case text-xl text-white top-0">TEAM DYL</a>

 




  </div>
 
</div>
    </div>
  );
};

export default Navbar;


