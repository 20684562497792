
import Particles from 'react-particles';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Main from './components/Main';
import Navbar from './components/Navbar';
import './index.css';
import Footer from './components/Footer';
function App() {
  return (
    <div className="App">
      <Particles />
    <Navbar />
    <Main />
    <About />
    
    <Contact />
    <Footer />
    </div>
  );
}

export default App;
